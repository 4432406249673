import React from "react";
import styled from "styled-components";

import { scroller } from "react-scroll";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  let navigate = useNavigate();

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Revisá nuestra suscripción</h1>
            <p className="font13">
              Probá Tu Taller completamente gratis con un número limitado de alumnos.
              <br />
              Cuando sea el momento, podés desbloquear todas las funcionalidades suscribiéndote a Tu Taller.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <Fade direction="left">
                <PricingTable
                  icon="jigsaw"
                  price="Gratis"
                  title="Prueba gratis"
                  text="Explorá las funciones principales de Tu Taller creando el espacio para tu taller, disponible para un pequeño limitado de alumnos."
                  offers={[
                    { name: "Creación de espacio para tu taller", cheked: true },
                    { name: "Personalización con logo y estilo", cheked: true },
                    { name: "Manejo de clases recurrentes", cheked: true },
                    { name: "Acceso a datos y reportes", cheked: false },
                    { name: "Soporte técnico", cheked: false },
                  ]}
                  studentLimit="Limite de usuarios: 10"
                  actionText="Probálo gratis"
                  action={() => navigate("/home/create")}
                />
              </Fade>
            </TableBox>
            <TableBox>
              <Fade direction="right">
                <PricingTable
                  icon="flag"
                  price="En construcción"
                  title="Suscripción"
                  text=" Habilitá tu espacio para todos tus alumnos con todas las funcionalidades de Tu Taller como soporte técnico, analisis de datos, y mucho más."
                  offers={[
                    { name: "Creación de espacio para tu taller", cheked: true },
                    { name: "Personalización con logo y estilo", cheked: true },
                    { name: "Manejo de clases recurrentes", cheked: true },
                    { name: "Acceso a datos y reportes", cheked: true },
                    { name: "Soporte técnico", cheked: true },
                  ]}
                  studentLimit="Limite de usuarios: 499"
                  actionText="¿Te interesa? Contactános"
                  action={() => scroller.scrollTo('contact', { spy: true, smooth: true })}
                />
              </Fade>
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 45%;
  // width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




