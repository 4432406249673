export function validator(key, value, component, validations) {
  var validationErrors = [];
  validations.forEach((validation) => {
    let validationErr = validation(value);
    if (validationErr && !validationErrors.length) {
      validationErrors.push(validationErr);
    }
  });

  var newState = component.state;
  if (validationErrors.length === 0) {
    delete newState.errors[key];
  } else {
    newState.errors[key] = validationErrors;
  }
  newState[key] = value;

  component.setState(newState);
}

export function isFormStateInvalid(module) {
  return Object.keys(module.state.errors).length > 0 || Object.values(module.state).filter(v => v === "").length > 0;
}

export function isRequired(value) {
  if (!value) {
    return "Campo requerido";
  }
  return null;
}

export function maxLength(maxLength) {
  return function (value) {
    if (value.length > maxLength) {
      return "La longitud máxima es " + maxLength;
    }
    return null;
  };
}

export function shouldMatchPassword(getExectedVal) {
  return function (value) {
    if (value !== getExectedVal()) {
      return "Las contraseñas no coinciden";
    }
    return null;
  };
}

export function isEmailValid(email) {
  const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(email)) {
    return "Email inválido";
  }
  return null;
}

export function isHexaColorValid(value) {
  const hexColorRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
  if (!hexColorRegex.test(value)) {
    return "Color hexadecimal inválido"; 
  }
  return null;
}

export function isAliasValid(alias) {
  // Define a regular expression for lowercase alphabetic letters
  const aliasPattern = /^[a-z-]+$/;

  // Check for length constraints
  if (alias.length < 4) {
    return "El alias debe tener al menos 4 caracteres.";
  }
  if (alias.length > 15) {
    return "El alias no puede tener más de 15 caracteres.";
  }
  // Check for valid characters
  if (!aliasPattern.test(alias)) {
    return "El alias solo puede contener letras minúsculas y guiones.";
  }

  // If all checks pass, return null
  return null;
}

export function isValidPassword(value) {
  return passwordChecker(value).err;
}
export function passwordMsg(value) {
  return passwordChecker(value).msg;
}

function passwordChecker(password, maxLength) {
  const createMessageLine = (condition, message) =>
    `${condition ? "✅" : "❌"} ${message}`;

  let msg = "";
  let err = false;

  const validations = [
    { condition: password.length >= 8, message: "Mínimo 8 caracteres" },
    { condition: /[A-Z]/.test(password), message: "Al menos una mayúscula" },
    { condition: /[a-z]/.test(password), message: "Al menos una minúscula" },
    { condition: /[0-9]/.test(password), message: "Al menos un número" },
    {
      condition: /[!@#$%^&*()_+{}[\]:;"'<>,.?/`~\\|-]/.test(password),
      message: "Al menos un carácter especial",
    },
  ];

  validations.forEach(({ condition, message }) => {
    const line = createMessageLine(condition, message);
    msg += line + "\n";
    err = err || !condition;
  });

  return { msg: msg, err: err ? "Contraseña inválida" : null };
}
