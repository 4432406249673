import axios from 'axios';

export function getDomainConfig(){
    if (window.location.hostname.includes("tutaller.com.ar")) {
        return {
            // host:"workshop-core-api.onrender.com",
            host:"workshop-core-989996246703.southamerica-east1.run.app",
            site_host:"tutaller.com.ar",
            protocol:"https",
        }
    }
    return {
        host:"localhost:8080",
        site_host:"localhost:8080",
        protocol:"http",
    } 
}

class HttpService {

    constructor() {
        var config = getDomainConfig()
        this.baseUrl = `${config.protocol}://${config.host}/api`;
        this.instance = axios.create({ baseURL: this.baseUrl });
    }

    get defaultHeaders() {
        return {
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        };
    }

    async request(method, url, data = null, customHeaders = {}) {
        const headers = { ...this.defaultHeaders, ...customHeaders };
        const source = axios.CancelToken.source();

        const config = {
            method,
            url,
            headers,
            cancelToken: source.token
        };

        if (data) {
            config.data = data;
        }

        try {
            const response = await this.instance(config);
            return response.data; // Return the response data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject(error.response ? error.response.data : error.message);
            }
            return Promise.reject(error); // Re-throw non-Axios errors
        }
    }

    get(url, customHeaders = {}) {
        return this.request('get', url, null, customHeaders);
    }

    post(url, data, customHeaders = {}) {
        return this.request('post', url, data, customHeaders);
    }

    put(url, data, customHeaders = {}) {
        return this.request('put', url, data, customHeaders);
    }

    delete(url, customHeaders = {}) {
        return this.request('delete', url, null, customHeaders);
    }
}

const apiService = new HttpService();
export default apiService;