// RedirectWithToken.js
import React, { useEffect } from "react";

const RedirectWithToken = ({ token, redirectURL }) => {
  useEffect(() => {
    // Create an iframe to load the subdomain
    const iframe = document.createElement("iframe");
    iframe.src = redirectURL + "/app/pages/e/r.html";
    iframe.style.display = "none"; // Hide the iframe
    document.body.appendChild(iframe);

    // Send the token using postMessage once the iframe loads
    const handleLoad = () => {
      iframe.contentWindow.postMessage({ authToken: token }, redirectURL);
      setTimeout(() => {
        window.location.href = redirectURL + "/app";
      }, 2000);
    };

    iframe.addEventListener("load", handleLoad);

    // Clean up
    return () => {
      iframe.removeEventListener("load", handleLoad);
      document.body.removeChild(iframe);
    };
  });

  return (
    <div>
      <h2>Redirigiendo a tu nuevo espacio...</h2>
    </div>
  );
};

export default RedirectWithToken;
