import React, { useState } from "react";
import { Box, Typography, Button, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  createSpace,
  sendVerificationCode,
} from "../../../../../api/space_creation";
import { loginPostCredentials } from "../../../../../api/auth";
import { getDomainConfig } from "../../../../../api/client";

import TokenRedirection from "../../Elements/TokenRedirection";
import ConfirmationCodeInput from "../../Elements/ConfirmationCodeInput";

const CompleteFormExample = ({
  getEmail,
  previousStep,
  goToStep,
  getFormState,
  isActive,
}) => {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState("");

  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [formError, setFormError] = useState("");

  const [accessToken, setAccessToken] = useState(undefined);
  const [redirectURL, setRedirectURL] = useState(undefined);

  const setConfirmationAndSubmit = (confirmationCode) => {
    var formState = getFormState();
    formState.confirmation_code = confirmationCode;
    createSpace(
      formState,
      (data) => {
        //  Save token
        var config = getDomainConfig();
        setRedirectURL(
          `${config.protocol}://${formState.space.alias}.${config.site_host}`
        );
        setAccessToken(data.admin_token);
      },
      (e) => {
        setFormError(
          e && e.msg ? e.msg : "Algo salió mal. Intentá nuevamente más tarde."
        );
        setIsValidatingCode(false);
        setIsCodeSent(false);
      }
    );
  };

  return (
    <Box sx={{ padding: 3, width: "100%" }}>
      <form>
        <Typography variant="h4" gutterBottom>
          Último paso
        </Typography>
        <Typography variant="body1" paragraph>
          Enviaremos un email con el código de confirmación a <strong>{getEmail()}</strong> para verificar la dirección de correo
          electrónico.
        </Typography>

        {isCodeSent && (
          <Box sx={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="confirmationCode"
            >
              Código de confirmación
            </Typography>
            {/* <TextField
            id="confirmationCode"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => setConfirmationCode(e.target.value)}
          /> */}
            <ConfirmationCodeInput setFinalInput={setConfirmationCode} />
          </Box>
        )}
        <hr />

        <Grid container spacing={2}>
          <Grid md={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                previousStep();
              }}
            >
              Volver
            </Button>
          </Grid>
          <Grid md={6}>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                setIsSendingCode(false);
                setIsCodeSent(false);
                setIsValidatingCode(false);
                goToStep(1);
              }}
            >
              Cambiar email
            </Button>
          </Grid>
          {!isCodeSent && (
            <Grid md={6}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isSendingCode}
                onClick={(e) => {
                  e.preventDefault();
                  setIsSendingCode(true);
                  setFormError(undefined);
                  sendVerificationCode(
                    getFormState(),
                    (data) => {
                      setIsSendingCode(false);
                      setIsCodeSent(true);
                    },
                    (err) => {
                      console.log("failed to send code:", err);
                      setIsSendingCode(false);
                      setIsCodeSent(false);
                      setFormError(
                        "Algo salió mal al enviar el código. Por favor, intentálo nuevamente más tarde."
                      );
                    }
                  );
                }}
              >
                {isSendingCode
                  ? "Enviando código...."
                  : "Enviar código de confirmación"}
              </Button>
            </Grid>
          )}
          {isCodeSent && (
            <>
              <Grid md={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  type={isCodeSent && isActive ? "submit" : "button"}
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    setIsValidatingCode(true);
                    setFormError(undefined);
                    setConfirmationAndSubmit(confirmationCode);
                  }}
                  disabled={confirmationCode.length < 6 || isValidatingCode}
                >
                  {isValidatingCode
                    ? "Validando..."
                    : "Validar y crear espacio"}
                </Button>
              </Grid>
            </>
          )}
          <FormHelperText error={true}> {formError} </FormHelperText>
        </Grid>
      </form>
      {redirectURL && accessToken && isCodeSent && (
        <TokenRedirection token={accessToken} redirectURL={redirectURL} />
      )}
    </Box>
  );
};

export default CompleteFormExample;
