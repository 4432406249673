export function subdomainCheck() {
    if (getSpaceAliasFromURL() !== ""){
        window.location.replace("/app/");
    }
}


function getSpaceAliasFromURL() {
    let rawURL = window.location.hostname.split(".");
    if (rawURL.length >= 2 &&
        (rawURL[1] === "tutaller" || rawURL[1].split(":")[0] === "localhost")
    ) {
        return rawURL[0]
    }
    // Not a valid URL with space alias
    return "";
}