import React, { useState } from "react";
import { Box, TextField } from "@mui/material";

const ConfirmationCodeInput = ({ setFinalInput }) => {
  const [confirmationCode, setConfirmationCode] = useState(Array(6).fill(""));

  const handleChange = (event, index) => {
    const value = event.target.value;

    if (/^\d?$/.test(value)) {
      const newCode = [...confirmationCode];
      newCode[index] = value;

      if (value && index < 5) {
        document.getElementById(`digit-${index + 1}`).focus();
      } else if (index > 0 && !value) {
        document.getElementById(`digit-${index - 1}`).focus();
      }

      setConfirmationCode(newCode);
      setFinalInput(newCode.join(""));
    }
  };

  const handlePaste = (event, index) => {
    const pastedData = event.clipboardData.getData("Text");
    const digits = pastedData.split("").filter((char) => /^\d$/.test(char));

    if (digits.length === 6) {
      const newCode = [...digits];
      setConfirmationCode(newCode);
      setFinalInput(newCode.join(""));
      // Optionally, focus the last input after pasting
      document.getElementById(`digit-5`).focus();
    }
    
    event.preventDefault(); // Prevent the default paste behavior
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}
    >
      {confirmationCode.map((digit, index) => (
        <TextField
          key={index}
          id={`digit-${index}`}
          type="text"
          variant="outlined"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onPaste={(e) => handlePaste(e, index)} // Add the onPaste handler
          sx={{ width: "50px", marginRight: index < 5 ? "10px" : "0" }}
          slotProps={{
            htmlInput: {
              style: { textAlign: "center", fontSize: "1.25em" },
              maxLength: 1,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default ConfirmationCodeInput;
